import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
class ClassDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchQuery: '', 
		};
	}
	handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery })
	render() { 
		let xtext = "Choose Category...";
		const options = this.props.options.map(x => {
			if (this.props.value === x.id) xtext = x.name;
			return {
				key: x.id,
				value: x.id,
				text: x.name,
				content: <div className="selectorContent"><div className="selName">{x.name}</div>{x.id !== "ALL" ? <Icon className="addbutton" name="plus" /> : null}</div>
			};
		});
		return (
			<Dropdown
			value={this.props.value}
			options={options}
			onChange={this.props.onChange}
			multiple
			//selection
			//fluid
			button
			className={"xlabeled icon"+(this.props.value.length === 1 ? " onlyone" : "")}
			floating
			//labeled
			icon="filter"
			search 
			text={xtext}
			noResultsMessage="Nothing found."
			searchQuery={this.state.searchQuery}
			onSearchChange={this.handleSearchChange}
		/>
		);
	}
}
export default withTranslation()(ClassDropdown);
